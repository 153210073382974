import { render, staticRenderFns } from "./race.vue?vue&type=template&id=2620188a&scoped=true"
import script from "./race.js?vue&type=script&lang=js&external"
export * from "./race.js?vue&type=script&lang=js&external"
import style0 from "./race.vue?vue&type=style&index=0&id=2620188a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2620188a",
  null
  
)

export default component.exports